import Vue from 'vue'
import App from './App.vue'
import router from './routes/index'
import store from './store'
import vueHeadful from 'vue-headful'
import vuetify from './plugins/vuetify'
import Vue2TouchEvents from 'vue2-touch-events'
import VImageInput from 'vuetify-image-input/a-la-carte'
import Clipboard from 'v-clipboard'
import './registerServiceWorker'

Vue.use(Clipboard)

Vue.use(Vue2TouchEvents)

Vue.component('vue-headful', vueHeadful)
Vue.component('VImageInput', VImageInput)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
