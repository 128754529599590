// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbptrwz0Do2fb9LLgGw00fG_Hnwhi1l1Q",
  authDomain: "iturnier-de-1584926803622.firebaseapp.com",
  databaseURL: "https://iturnier-de-1584926803622.firebaseio.com",
  projectId: "iturnier-de-1584926803622",
  storageBucket: "iturnier-de-1584926803622.appspot.com",
  messagingSenderId: "644232720034",
  appId: "1:644232720034:web:850d47bf1d27163a5cf1fb",
  measurementId: "G-VKRGH7LL40"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider('6Ld9JGYdAAAAABdHKf7mGgFHk4SzFqXpP9aBtaHB'),
//   isTokenAutoRefreshEnabled: true,
// })
