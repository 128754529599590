<template>
  <v-app style="width: 100vw;">
    <vue-headful
      :title="$route.name ? $route.name + ' | iTurnier.de' : 'iTurnier.de'"
    />
    <div v-if="!user.loader">
      <router-view stlye="width: 100vw;"></router-view>
    </div>
    <Loader v-if="user.loader" />
  </v-app>
</template>

<script>
import Loader from './components/loader/loader'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  components: {
    Loader,
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: "Roboto";
  font-weight: 100;
  font-style: normal;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Thin.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Thin.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  font-style: normal;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Light.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Light.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Regular.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Medium.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  font-style: bold;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Bold.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 900;
  font-style: bold;
  src: local("Roboto"), url("@/assets/fonts/Roboto/Roboto-Black.ttf") format("ttf"), url("@/assets/fonts/Roboto/Roboto-Black.ttf") format("ttf");
}

/* Regular */
@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    src: local('Segoe UI'), local('Segoe UI'), url('./assets/font/SegoeUI.woff2') format('woff2');
  }
  /* Semibold */
  @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    src: local('Segoe UI'), local('Segoe UI'), url('./assets/font/SegoeUI-Semibold.woff2') format('woff2');
  }
  /* Bold */
  @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 800;
    src: local('Segoe UI'), local('Segoe UI'), url('./assets/font/SegoeUI-Bold.woff2') format('woff2');
  }

body, .v-application {
  font-family: "Segoe UI" !important;
}

.vox-italic {
    font-family: "vox", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.vox-regular {
    font-family: "vox", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.vox-bold {
    font-family: "vox", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.vox-bold-italic {
    font-family: "vox", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.vox-round-regular {
    font-family: "vox-round", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.vox-round-italic {
    font-family: "vox-round", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.vox-round-bold {
    font-family: "vox-round", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.vox-round-bold-italic {
    font-family: "vox-round", sans-serif;
    font-weight: 700;
    font-style: italic;
}

:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}

.v-navigation-drawer__border {
  background-color: unset !important;
}

*::-webkit-scrollbar {
  display: none;
}

.v-card--link:focus:before {
  opacity: 0 !important;
}

.v-bottom-navigation {
  height: auto !important;
  padding-top: 10px !important;
  padding-bottom: 10px;
}

.v-bottom-navigation >>> .v-btn__content {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-bottom: constant(
    safe-area-inset-bottom
  ); /* move footer up on iPhone X - iOS 11.0 */
  margin-bottom: (
    10px + env(safe-area-inset-bottom)
  ); /* move footer up on iPhone X - iOS 11.2 */
}

.grow {
  transition: all 1.5s ease-in-out;
  cursor: pointer !important;
}
.grow:hover {
  transform: scale(1.05);
  cursor: pointer !important;
}

.outlined-card {
  border: 3px solid #fff !important;
  background-color: unset !important;
  padding: 15px;
}

.h-100 {
  height: 100% !important;
}

.v-dialog__content--active {
  backdrop-filter: blur(10px);
} 
</style>
