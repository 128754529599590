import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

/* add icons to the library */
library.add(fas, far, fal, fad, fat, fab)

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'faSvg',
    values: {
      notification: 'fas fa-bell',
      messages: 'fas fa-comments-alt',
      close: 'fas fa-time',
      BASIC: 'mdi-soccer',
      PREMIUM: 'mdi-star',
      PRO: 'mdi-podium',
      VIP: 'mdi-diamond-stone',
      VISA: 'fab fa-cc-visa',
      MASTERCARD: 'fab fa-cc-mastercard',
      AMEX: 'fab fa-cc-amex',
      CCARD: 'fas fa-credit-card',
    },
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#000000',
        primaryt25: 'rgba(0,0,0,0.75)',
      },
      light: {
        primary: '#FFFFFF',
        primaryt25: 'rgba(0,0,0,0.75)',
      },
    },
  },
}

export default new Vuetify(opts)
