import { doc, getFirestore, updateDoc } from "firebase/firestore";

var Base64 = {

    // private property
    _keyStr : "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    
    // public method for encoding
    encode : function (input) {
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
    
        input = Base64._utf8_encode(input);
    
        while (i < input.length) {
    
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
    
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
    
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
    
            output = output +
            this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
            this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
        }
        return output;
    },
    
    // public method for decoding
    decode : function (input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
    
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    
        while (i < input.length) {
    
            enc1 = this._keyStr.indexOf(input.charAt(i++));
            enc2 = this._keyStr.indexOf(input.charAt(i++));
            enc3 = this._keyStr.indexOf(input.charAt(i++));
            enc4 = this._keyStr.indexOf(input.charAt(i++));
    
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
    
            output = output + String.fromCharCode(chr1);
    
            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }
        }
    
        output = Base64._utf8_decode(output);
    
        return output;
    },
    
    // private method for UTF-8 encoding
    _utf8_encode : function (string) {
        string = string.replace(/\r\n/g,"\n");
        var utftext = "";
    
        for (var n = 0; n < string.length; n++) {
    
            var c = string.charCodeAt(n);
    
            if (c < 128) {
                utftext += String.fromCharCode(c);
            }
            else if((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
        }
        return utftext;
    },
    
    // private method for UTF-8 decoding
    _utf8_decode : function (utftext) {
        var string = "";
        var i = 0;
        var c = c1 = c2 = 0;
    
        while ( i < utftext.length ) {
    
            c = utftext.charCodeAt(i);
    
            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            }
            else if((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i+1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            }
            else {
                c2 = utftext.charCodeAt(i+1);
                c3 = utftext.charCodeAt(i+2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }
        return string;
    }
}

export class SpotifyPlayer {
    constructor(user){
        this.user = user
        this.music = {
            song_title: '',
            artists: '',
            cover: '',
            paused: true
        }
        this.player = ''
        this.init()
    }

    spotify_not_connected(){
        if(this.user.data.spotify?.access_token){
            return false
        }
        return true
    }

    spotify_connected(){
        if(this.user.data.spotify?.access_token){
            return true
        }
        return false
    }

    init(){
        if(spotify_ready && this.spotify_connected && !this.player){
            const token = this.user.data.spotify?.access_token
            this.player = new Spotify.Player({
                name: 'Turnierleitung',
                getOAuthToken: cb => { 
                    const spotify_client_id = '12a9c678456441249ded76485defa4f6'
                    const spotify_client_secret = '5610f6be5f714eb381b99e5f067080ed'
                    if(this.user.data.spotify?.refresh_token){
                        fetch('https://accounts.spotify.com/api/token', {
                            method: 'POST',
                            headers: {
                                'Authorization': 'Basic ' + Base64.encode(spotify_client_id + ':' + spotify_client_secret).toString(),
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body: 'refresh_token='+this.user.data.spotify?.refresh_token+'&grant_type=refresh_token'
                        }).then(response => {
                            if(response.status == 200){
                                return response.json()
                            }
                        }).then(async data => {
                            if(data){
                                await updateDoc(doc(getFirestore(), 'User/'+this.user.data.uid), {
                                    spotify: {
                                        access_token: data.access_token,
                                        refresh_token: this.user.data.spotify?.refresh_token
                                    }
                                })
                                cb(data.access_token)
                            }
                        })
                    }
                    else {
                        cb(token); 
                    }
                },
                volume: 0.5
            });


            // Ready
            this.player.addListener('ready', ({ device_id }) => {
                console.log('Ready with Device ID', device_id);
            });

            // Not Ready
            this.player.addListener('not_ready', ({ device_id }) => {
                console.log('Device ID has gone offline', device_id);
            });

            this.player.addListener('initialization_error', ({ message }) => {
                console.error(message);
            });

            this.player.addListener('player_state_changed', ({
                position,
                duration,
                track_window: { current_track }
            }) => {
                console.log('Currently Playing', current_track);
                console.log('Position in Song', position);
                console.log('Duration of Song', duration);
                var artist = ''
                current_track.artists.forEach((item, index) => {
                    artist += item.name
                    if(index+2 == current_track.artists.length){
                        artist += ' & '
                    }
                    else if(index+1 < current_track.artists.length){
                        artist += ', '
                    }
                })
                this.music.artists = artist
                this.music.song_title = current_track.name

                this.music.cover = current_track.album.images.find(item => item.size == 'SMALL')?.url

                this.player.getCurrentState().then(state => {
                    if(state){
                        console.log(state)
                        this.music.paused = state.paused
                    }
                    else {
                        this.music = {
                            song_title: '',
                            artists: '',
                            paused: true
                        }
                    }
                })
            });

            this.player.addListener('authentication_error', async ({ message }) => {
                console.error(message);
                await updateDoc(doc(getFirestore(), 'User/'+this.user.data.uid), {
                    spotify: {
                        access_token: '',
                        refresh_token: ''
                    }
                })
            });

            this.player.addListener('account_error', ({ message }) => {
                console.error(message);
            });

            this.player.connect();
            this.player.activateElement();
        }
    }
    
    async logout(){
        await updateDoc(doc(getFirestore(), 'User/'+this.user.data.uid), {
            spotify: {
                access_token: '',
                refresh_token: ''
            }
        })
        this.music = {
            song_title: '',
            artists: '',
            paused: true
        }
        this.player?.disconnect()
    }

    fade_in(volume = 0, interval = 100){
        this.player?.setVolume(volume > 1 ? 1 : volume)
        this.player?.resume()
        if(volume < 1){
            setTimeout(() => {
                volume += 0.025
                this.fade_in(volume)
            }, interval)
        }
    }

    fade_out(volume = 1, interval = 100){
        this.player?.setVolume(volume < 0 ? 0 : volume)
        if(volume > 0){
            setTimeout(() => {
                volume -= 0.025
                this.fade_out(volume)
            }, interval)
        }
        if(volume <= 0){
            this.player?.pause()
        }
    }

    play(){
        this.player?.resume()
    }

    pause(){
        this.player?.pause()
    }

    next(){
        this.player?.nextTrack()
    }

    prev(){
        this.player?.previousTrack()
    }

}