/* MODULES */
import Vue from 'vue'
import Router from 'vue-router'
//import auth from '../auth/auth'
import NProgress from 'nprogress'

/* COMPONENTS */
const Login = () => import('../components/login/login.vue')
const SignUp = () => import('../components/login/sign-up.vue')
const NeedVerification = () =>
  import('../components/login/need-verification.vue')
const VerifyMail = () => import('../components/login/verify-mail.vue')
const Dashboard = () => import('../components/dashboard/dashboard.vue')
const Userconfig = () => import('../components/userconfig/userconfig.vue')
const usersites = () => import('../components/usersites.vue')
const Settings = () => import('../components/userconfig/settings/settings.vue')
const MyAccount = () =>
  import('../components/userconfig/myaccount/myaccount.vue')
const MyClub = () => import('../components/userconfig/myclub/myclub.vue')
const Membership = () =>
  import('../components/userconfig/membership/membership.vue')
const MembershipInfo = () =>
  import('../components/userconfig/membership/info.vue')
const MembershipZahlungsart = () =>
  import('../components/userconfig/membership/zahlungsart.vue')
const MembershipVerlaengerung = () =>
  import('../components/userconfig/membership/verlaengerung.vue')
const MembershipUpgrade = () =>
  import('../components/userconfig/membership/upgrade.vue')
const finishprofile = () => import('../components/user/finishprofile.vue')
const membership_payment = () =>
  import('../components/membership/payment/payment.vue')
const membership_complete_payment = () =>
  import('../components/membership/payment/complete-payment.vue')

const SpotifyCallback = () => import('../components/spotify/callback.vue')



//Routes
import turnierroutes from './turnier'
import { getAuth } from 'firebase/auth'

//const auth = getAuth()

Vue.use(Router)

const routes = [
    turnierroutes.anzeigetafel,
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
  },
  {
    path: '/registrieren',
    name: 'Registrierung',
    component: SignUp,
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
    beforeEnter: (to, from, next) => {
      if (auth.getUser()) {
        next('dashboard')
      } else {
        next()
      }
    },
  },
  {
    path: '/email-bestaetigen',
    name: 'E-Mail bestätigen',
    component: NeedVerification,
    meta: {
      requiresAuth: true,
      allowUnverified: true,
    },
  },
  {
    path: '/verifymail',
    name: 'verifymail',
    component: VerifyMail,
    props: (route) => ({ code: route.query.oobCode, mode: route.query.mode }),
    meta: {
      requiresAuth: false,
      allowUnverified: true,
    },
  },
  {
    path: '/user/finish',
    name: 'Profil vervollständigen',
    component: finishprofile,
    meta: {
      requiresAuth: true,
      allowUnverified: true,
    },
  },
  {
    path: '/mitgliedschaft/bezahlen',
    name: 'Mitgliedschaft bezahlen',
    component: membership_payment,
    meta: {
      requiresAuth: true,
      allowUnverified: true,
    },
  },
  {
    path: '/mitgliedschaft/zahlung-abschliessen',
    name: 'Mitgliedschaft bezahlen',
    component: membership_complete_payment,
    meta: {
      requiresAuth: true,
      allowUnverified: true,
    },
  },
  {
    path: '/',
    component: usersites,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        redirect: '/dashboard',
      },
      {
        path: '/spotify-connect',
        component: SpotifyCallback,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
      },
      turnierroutes.turnier,
      {
        path: '/user',
        component: Userconfig,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '/user',
            redirect: '/user/einstellungen',
          },
          {
            path: '/user/einstellungen',
            name: 'Einstellungen',
            component: Settings,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/user/mein-account',
            name: 'Mein Account',
            component: MyAccount,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/user/mein-verein',
            name: 'Mein Verein',
            component: MyClub,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: '/user/meine-mitgliedschaft',
            component: Membership,
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: '/user/meine-mitgliedschaft',
                name: 'Meine Mitgliedschaft',
                component: MembershipInfo,
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: 'zahlungsart',
                name: 'Meine Mitgliedschaft > Zahlungsart',
                component: MembershipZahlungsart,
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: 'verlaengerung',
                name: 'Meine Mitgliedschaft > Verlängerung',
                component: MembershipVerlaengerung,
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: 'upgrade',
                name: 'Meine Mitgliedschaft > Upgrade',
                component: MembershipUpgrade,
                meta: {
                  requiresAuth: true,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/:alias',
    name: 'Turnierseite',
    component: () => import('@/views/turnierseite/index.vue'),
    children: [
      {
        path: '',
        name: 'Startseite',
        component: () => import('@/views/turnierseite/startseite/index.vue'),
      },
      {
        path: 'spielplan',
        name: 'Spielplan',
        component: () => import('@/views/turnierseite/spielplan/index.vue'),
      },
      {
        path: 'statistiken',
        name: 'Statistiken',
        component: () => import('@/views/turnierseite/statistiken/index.vue'),
      },
      {
        path: '*',
        component: () => import('@/views/error-seiten/404.vue')
      }
    ],
  },
  {
    path: '*',
    component: () => import('@/views/error-seiten/404.vue')
  }
  
  
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
})



router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
