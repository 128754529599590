import {
  getFirestore,
  doc,
  onSnapshot,
  collection,
  where,
  query,
} from '@firebase/firestore'
import rundeConverter from '../classes/rundeConverter'
import teamConverter from '../classes/spiel/teamConverter'
import Turnier from '../classes/turnier/turnier'
import turnierConverter from '../classes/turnier/turnierConverter'

const db = getFirestore()

const TurnierModule = {
  state: {
    turnier: new Turnier(),
    turnierunsubsribe: () => {},
    aktuelles_spiel: ''
  },
  getters: {
    turnier(state) {
      return state.turnier
    },
    aktuelles_spiel(state) {
      return state.aktuelles_spiel
    }
  },
  mutations: {
    UNSET_TURNIER(state) {
      state.turnier.destructor()
      state.turnier = ''
    },
    SET_TURNIER(state, data) {
      state.turnierunsubscribe = onSnapshot(
        doc(db, '/Turniere/' + data.id).withConverter(turnierConverter),
        (doc) => {
          state.turnier = doc.data()
        },
      )
    },
  },
  actions: {
    closeTurnier({ commit }) {
      commit('UNSET_TURNIER')
    },
    openTurnier({ commit }, data) {
      commit('SET_TURNIER', data)
    },
  },
}

export default TurnierModule
