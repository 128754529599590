<template>
  <v-container fluid>
    <v-overlay class="text-center">
      <v-img
        src="../../assets/logos/iturnier-squared.png"
        :max-width="$vuetify.breakpoint.mdAndUp ? '10vw' : '25vw'"
      ></v-img>
      <br />
      <v-progress-linear
        indeterminate
        color="white"
      ></v-progress-linear>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: 'loader',
}
</script>

<style scoped>
svg {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(1);
  transform: translate(-50%, -50%) rotate(45deg) scale(1);
}

.stroke-still {
  stroke: #232323;
}

.stroke-animation {
  -webkit-animation: stroke-spacing 1.2s ease-in, stroke-color 4.8s linear;
  animation: stroke-spacing 1.2s ease-in, stroke-color 4.8s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

@-webkit-keyframes stroke-spacing {
  0% {
    stroke-dasharray: 0 200;
  }
  45% {
    stroke-dashoffset: 0;
    stroke-dasharray: 200 200;
  }
  90% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
  100% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
}

@keyframes stroke-spacing {
  0% {
    stroke-dasharray: 0 200;
  }
  45% {
    stroke-dashoffset: 0;
    stroke-dasharray: 200 200;
  }
  90% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
  100% {
    stroke-dashoffset: -200;
    stroke-dasharray: 200 200;
  }
}

@-webkit-keyframes stroke-color {
  0% {
    stroke: rgb(81, 168, 0);
  }
  24% {
    stroke: #00e927;
  }
  25% {
    stroke: #2fff2f;
  }
  49% {
    stroke: #0b7010;
  }
  50% {
    stroke: #28a117;
  }
  74% {
    stroke: #3affef;
  }
  75% {
    stroke: #ffffff;
  }
  99% {
    stroke: #327844;
  }
}

@keyframes stroke-color {
  0% {
    stroke: rgb(81, 168, 0);
  }
  24% {
    stroke: #00e927;
  }
  25% {
    stroke: #2fff2f;
  }
  49% {
    stroke: #0b7010;
  }
  50% {
    stroke: #28a117;
  }
  74% {
    stroke: #3affef;
  }
  75% {
    stroke: #ffffff;
  }
  99% {
    stroke: #327844;
  }
}
</style>
