import Gruppe from "./gruppe"
import Team from "./team"
import store from '@/store.js'

export default class Mannschaft {
    constructor(id, name, tore = 0, gruppe, team, link){
        this.id = id
        this.name = name
        this.tore = tore || 0
        this.gruppe = gruppe
        this.team = team
        if(team){
            var t = store.state.TurnierModule.turnier.teams?.find(item => item.id == team)
            if(t){
              this.team = t
            }
            else {
                var item = store.state.turnierseite.teams?.find(item => item.id == team)
                this.team = item
            }
        }
        this.link = link
    }

    get_name(){
        if(this.team?.id){
            return this.team.name
        }
        else if(this.link?.name){
            return this.link.name
        }
        return this.name
    }
    
    get_logo(){
        if(this.team?.id){
            return this.team.logo
        }
        return ''
    }

    toFirestore(){
        return {
            id: this.id,
            name: this.name,
            tore: this.tore || 0,
            gruppe: this.gruppe instanceof Gruppe ? this.gruppe.toFirestore() : this.gruppe ? this.gruppe : '',
            team: this.team instanceof Team ? this.team.toFirestore().id : this.team instanceof String ? this.team : '',
            link: this.link || ''
        }
    }
}