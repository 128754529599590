//TURNIER
const Turnier = () => import('../components/turnier/turnier.vue')
const TurnierDashboard = () => import('../components/turnier/dashboard.vue')

// TURNIER -> PLANUNG
const TurnierPlanung = () => import('../components/turnier/planung/index.vue')
const TurnierEinladungMail = () =>
  import('../components/turnier/planung/turniereinladung/email.vue')
const TurnierEinladung = () =>
  import('../components/turnier/planung/turniereinladung/index.vue')

const TurnierAnmeldung = () =>
  import('../components/turnier/planung/anmeldung/index.vue')
const TurnierAnmeldungEinrichtung = () =>
  import('../components/turnier/planung/anmeldung/einrichtung.vue')
const TurnierAnmeldungConfig = () =>
  import('../components/turnier/planung/anmeldung/config.vue')
const TurnierWebsite = () =>
  import('../components/turnier/planung/turnierseite/index.vue')

const TurnierSponsoren = () =>
  import('../components/turnier/planung/sponsoren/index.vue')

const Anzeigetafel = () =>
  import('../components/turnier/durchfuehrung/anzeigetafel/index.vue')

const turnierroutes = {
  anzeigetafel: {
    path: '/turnier/:id/anzeigetafel',
    component: Anzeigetafel,
  },
  turnier: {
    path: '/turnier/:id',
    component: Turnier,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/turnier/:id',
        redirect: '/turnier/:id/dashboard',
      },
      {
        path: '/turnier/:id/dashboard',
        component: TurnierDashboard,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/turnier/:id/planung',
        component: TurnierPlanung,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'anmeldung',
            name: 'Turnier > Planung > Anmeldung',
            component: TurnierAnmeldung,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'anmeldung/einstellungen',
            name: 'Turnier > Planung > Anmeldung > Einstellungen',
            component: TurnierAnmeldungConfig,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'anmeldung/einrichten',
            name: 'Turnier > Planung > Anmeldung > Einrichtung',
            component: TurnierAnmeldungEinrichtung,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'website',
            name: 'Turnierplanung - Website einrichten',
            component: TurnierWebsite,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'einladung',
            name: 'Turnier > Planung > Turniereinladung',
            component: TurnierEinladung,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'einladung/email',
            name: 'Turnier > Planung > Turniereinladung > E-Mail erstellen',
            component: TurnierEinladungMail,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'sponsoren',
            name: 'Turnier > Planung > Sponsoren',
            component: TurnierSponsoren,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: '/turnier/:id/vorbereitung',
        component: () => import('../components/turnier/vorbereitung/index.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'teams',
            component: () =>
              import('../components/turnier/vorbereitung/teams/index.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'spielplan',
            component: () =>
              import('../components/turnier/vorbereitung/spielplan/index.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: '/turnier/:id/durchfuehrung',
        component: () => import('../components/turnier/vorbereitung/index.vue'),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'turnierleitung',
            component: () =>
              import('../components/turnier/durchfuehrung/turnierleitung/index.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'ergebnisse',
            component: () =>
              import('../components/turnier/durchfuehrung/ergebnisse/index.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: '/turnier/:id/cafeteria',
        component: () => import('../components/turnier/cafeteria/index.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: '/turnier/:id/einstellungen',
        component: () => import('../components/turnier/einstellungen/index.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: '*',
        component: () => import('@/views/error-seiten/404.vue')
      }
    ],
  },
}

export default turnierroutes
