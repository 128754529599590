import Vue from 'vue'
import Vuex from 'vuex'
import firebase from './firebaseConfig'
import style from './template'
import UserModule from './store/user'
import TurnierModule from './store/turnier'
import { getFirestore, onSnapshot, query, where } from 'firebase/firestore'
import { collection, doc, setDoc, getDoc, getDocs } from 'firebase/firestore'
import md5 from 'md5'
import rundeConverter from './classes/rundeConverter'
import teamConverter from './classes/spiel/teamConverter'
import turnierConverter from './classes/turnier/turnierConverter'
import { Theme } from './classes/turnier/website/theme'
import { SpotifyPlayer } from './classes/spotify/spotify-player'

const db = getFirestore()

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    menu: {
      name: 'Hauptmenu',
      menulinks: [
        {
          name: 'Dashboard',
          icon: 'fas fa-grip-horizontal fa-2x',
          link: '/dashboard',
        },
        {
          name: 'Nachrichtencenter',
          icon: 'fas fa-mail-bulk fa-2x',
          link: '/nachrichten',
        },
        {
          name: 'Meine Turniere',
          icon: 'fad fa-trophy fa-2x',
          link: '/meine-turniere',
        },
        {
          name: 'Anmeldungen verwalten',
          icon: 'fad fa-users fa-2x',
          link: '/anmeldungen',
        },
        {
          name: 'Turnierseite anpassen',
          icon: 'fad fa-browser fa-2x',
          link: '/turnierseite',
        },
      ],
      expanded: true,
      mobile: false,
    },
    turnierseite: {
      id: '',
      data: '',
      runden: [],
      sponsoren: [],
      verein: '',
      spiele: [],
      unsubscribe: () => {},
      sponsorunsubscribe: () => {},
    },
    seite: {
      name: '',
    },
    template: style.getTemplate('neon01'),
    design: style.getAdminTemplate(''),
    countdown: '',
    clock: {
      hours: '',
      minutes: '',
      seconds: '',
      date: '',
    },
    spotify: {
      player: ''
    }
  },
  getters: {
    menu(state) {
      return state.menu
    },
    
    spotify(state) {
      return state.spotify.player
    },

    turnierseite(state) {
      return state.turnierseite
    },
    template(state) {
      return state.template
    },
    theme(state){
      if(state.turnierseite?.website?.theme){
        return state.turnierseite.website.theme
      }
      return new Theme()
    },
    design(state) {
      return state.design
    },
    countdown(state) {
      return state.countdown
    },
    seite(state) {
      return state.seite
    },
    clock(state) {
      return state.clock
    },
  },
  mutations: {
    START_CLOCK(state) {
      var clock = () => {
        var now = new Date()
        state.clock.hours = now.getHours().toString().padStart(2, '0')
        state.clock.minutes = now.getMinutes().toString().padStart(2, '0')
        state.clock.seconds = now.getSeconds().toString().padStart(2, '0')
        state.clock.date =
          now.getDate().toString().padStart(2, '0') +
          '.' +
          (now.getMonth() + 1).toString().padStart(2, '0') +
          '.' +
          now.getFullYear()
        setTimeout(() => {
          clock()
        }, 500)
      }
      clock()
    },
    SET_COUNTDOWN(state, countdown) {
      state.countdown = countdown
    },
    SET_TEMPLATE(state, template) {
      state.template = style.getTemplate(template)
    },
    SET_SPOTIFY_PLAYER(state, spotify) {
      if(spotify instanceof SpotifyPlayer){
        state.spotify.player = spotify
      }
    },
    SET_DESIGN(state, template) {
      state.design = style.getAdminTemplate(template)
    },
    async SET_TURNIERSEITE(state, params) {
      if (params.alias) {
        const turnierRef = query(collection(getFirestore(), 'Turniere'), where('alias', '==', params.alias)).withConverter(turnierConverter)
        const turnierSnap = onSnapshot(turnierRef, snap => {
          if(snap.size > 0){
            state.turnierseite = snap.docs[0].data()
          }
          else {
            this.$router.push('/dashboard')
          }
        })
      }
      else {
        this.$router.push('/dashboard')
      }
    },

    SET_MENU(state, name, menudata) {
      state.menu.name = name
      state.menu.menulinks = menudata
    },
    TOGGLE_MOBILE_NAV(state, value) {
      state.menu.mobile = value
    },
  },
  actions: {
    openTurnierseite({ commit }, params) {
      commit('SET_TURNIERSEITE', params)
    },
    setCountdown({ commit }, params) {
      commit('SET_COUNTDOWN', params)
    },
    setTemplate({ commit }, params) {
      commit('SET_TEMPLATE', params)
    },
    setSpotifyPlayer({ commit }, params) {
      commit('SET_SPOTIFY_PLAYER', params)
    },

    fetchMenu({ commit }, menu) {
      commit('SET_MENU', menu.name, menu.data)
    },
    startClock({ commit }) {
      commit('START_CLOCK')
    },
    toggleMobileNav({ commit }, value) {
      commit('TOGGLE_MOBILE_NAV', value)
    },
  },
})

store.registerModule('UserModule', UserModule)
store.registerModule('TurnierModule', TurnierModule)

export default store
